:root {
	--grey-background-color: #EEEEEF;
	--white-background-color: #FFF;
	--grey-btn-background-color: #ABABAC;
	--red-color : #D71414;
	--dc-primary-color: #666;
	--ag-primary-color: #333;
	--duplicate-header-template-row:  0.3fr;
	--export-header-template-row:  0.2fr;
	--import-header-template-row:  0.08fr;
	--duplicate-project-template-row:  0.93fr;
	--export-project-template-row:  0.65fr;
	--import-project-template-row:  0.82fr;
	--duplicate-footer-template-row:  0.05fr;
	--export-footer-template-row:  0.1fr;
	--footer-template-row:  0.1fr;
}

.project_to_import {
	display: flex;
	justify-content: center;
	font-family: var(--font-family-title, 'sans-serif'), sans-serif;
	font-weight: 400;
	font-size: 20px;
}

.select_file_to_upload_container {
	display: flex;
	justify-content: center;
	padding-top: 32px;
}

.select_file_to_upload_dash {
	display:flex;
	justify-content: center;
	width: 60%;
}

.import-modal-header {
	padding: 10px;
	border-bottom: 1px solid #e5e5e5;
	max-height: 40px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	font-weight: 600;
	font-size: 1em;
	font-family: var(--font-family-title);
	color: var(--ag-primary);
	background-color: var(--grey-background-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 0.938em;
}

.source_project_informations {
	display: flex;
}

.import_project_container {
	display: grid;
	padding-block: 1rem;
	padding: 1rem 0 0 1rem;
	grid-template-areas:
		"source source source"
		"project project project"
		"footer footer footer";
	/*grid-template-rows:*/
	/*	var(--import-header-template-row) var(--import-project-template-row) var(--export-footer-template-row);*/
	height: 95vh;
	grid-row-gap: 0.625rem;
	grid-template-rows: 1fr 10fr 1fr;
}

.import-project_source {
	grid-area: source;
}

.import_project__config {
	grid-area : project;
	margin-top: 1rem;
	display: grid;
	grid-template-areas: "basic elements expositions";
	grid-template-columns: repeat(3,1fr);
	grid-column-gap: 1em;
	overflow-y: auto;
}

.import_project__consumers-mapping {
	grid-area : project;
	margin-top: 1rem;
	padding-inline: 1rem;
	overflow-y: scroll;
}

.import_project__config-basic {
	grid-area : basic;
}

.import_project__config-elements {
	grid-area : elements;
}

.import_project__config-expositions {
	grid-area : expositions;
}

.import_project__config-expositions__title {
	font-weight: 400;
	font-family: var(--font-family-title);
	color: var(--ag-primary-color);
	font-size: 1em;
}

.import-project_source-details {
	background-color: #EEEEEF;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 86px;
	padding: 12px;
}

.import_project__footer {
	grid-area: footer;
	display: flex;
	justify-content: flex-end;
	padding-inline: 1rem;
	text-align: right;
	align-items: center;
	padding-block: 1rem;
	margin-top: 1rem;
}

.import_project__footer--in-config {
	border-top: 1px solid #e5e5e5;
}

.import_project__footer-btn {
	display: flex;
	align-items: center;
	border-radius : 0.25em;
	color: white;
	margin-left : 1rem;
	padding: 0.188em 0.375em;
	border: 1px solid transparent;
}

.import_project__footer-btn.submit {
	background-color: hsl(180, 100%, 20%);
}

.import_project__footer-btn.submit--disabled {
	background-color: var(--grey-btn-background-color);
}

.import_project__footer-btn.cancel {
	background-color: var(--grey-btn-background-color);
}


.import-project__project {
	grid-area: project;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.project-import-progress-bar {
	padding-inline: 20rem;
}
