body:has(app-exposition-view) {
	.container {
		width: 95%;
	}

	.right-menu {
		margin-left: 4rem;
		margin-top: 1rem;
	}
}

body:has(app-expositions-list:not([data-embedded])) {
	.container {
		width: 95%;
	}

	.right-menu {
		margin-left: 4rem;
		margin-top: 1rem;
	}
}

body:has(app-exposition-migrations-list) {
	.container {
		width: 95%;
	}

	.right-menu {
		margin-left: 4rem;
		margin-top: 1rem;
	}
}

body:has(app-exposition-history-list) {
	.container {
		width: 95%;
	}

	.right-menu {
		margin-left: 4rem;
		margin-top: 1rem;
	}
}

body:has(app-exposition-simulator) {
	.container {
		width: 95%;
	}

	.right-menu {
		margin-left: 4rem;
		margin-top: 1rem;
	}
}
